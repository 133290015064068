<template>
  <div class="body-bg">
    <div class="login">
      <img src="../assets/images/home/loading.gif" alt="" />
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import { jsApiList } from '@/config'
  import { Toast } from 'vant'
  import { getJSAPIToken } from '@/api/dd/dd'
  import { getTeamInfo, ddLogin } from '@/api/base/base'
  import * as sensors from '@/api/login/login'

  export default {
    name: 'Home',
    beforeRouteEnter(to, from, next) {
      if (from.path === '/index') {
        window.dd
          .closePage()
          .then((res) => {})
          .catch((err) => {})
      } else {
        return next()
      }
    },
    data() {
      return {
        code: '',
        flag: false,
      }
    },

    mounted() {
      this.getAuthCode()
    },
    methods: {
      getAuthCode() {
        let that = this
        window.dd
          .getAuthCode({
            corpId: '',
          })
          .then(async (result) => {
            that.code = result.code
            await that.handleDingLogin()
          })
          .catch((err) => {
            Toast.fail('免密登录失败，请联系管理员！')
          })
      },

      async handleDingLogin() {
        const { data } = await ddLogin({ code: this.code })
        let userInfo = {
          User_ID: data.User_ID,
          Dept_ID: data.Dept_ID,
          Role_ID: data.Role_ID,
          Staff_Name: data.Staff_Name,
        }
        let token = data.token
        await store.dispatch('user/getUserInfo', userInfo)
        await store.dispatch('user/setToken', token)
        sensors.login('mqsb')
        await this.getJSAPIToken()
        await this.getTeamInfo()
        if (data.Role_ID == '11') {
          await this.$router.push('/echarts')
        } else {
          await this.$router.push('/index')
        }
      },

      async getJSAPIToken() {
        const { data } = await getJSAPIToken()
        window.dd
          .authConfig({
            ticket: data,
            jsApiList: jsApiList,
          })
          .then((res) => {})
          .catch((err) => {})
      },

      async getTeamInfo() {
        let userInfo = JSON.parse(store.getters['user/userInfo'])
        const { data } = await getTeamInfo({ User_ID: userInfo.User_ID })
        await store.dispatch('user/setTeamInfo', JSON.stringify(data))
      },
    },
  }
</script>
<style scoped>
  .login img {
    width: 150%;
    margin-left: -25%;
  }

  .body-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-size: 100%;
    background-color: #fff;
  }
</style>
